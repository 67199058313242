<template>
  <v-col>
    <loader :color="companyData.companyColor" v-if="loading" />


    <v-col sm="12" class="main-body" v-else>
      <v-col sm="12" class=" tw-flex tw-justify-between tw-items-center">
        <button
            class="tw-flex tw-items-center tw-mt-14 lg:tw-mt-6 overview-btn"
            @click="backToTerminals"
        >
          <icons name="arrow-back" :color="companyData.companyColor" />


          <span class="tw-ml-4" :style="{ color: companyData.companyColor }"
          >All Terminals</span
          >
        </button>
      </v-col>

      <v-col sm="12" class="tw-bg-white tw-mt-7 tw-rounded-xl tw-py-8 lg:tw-px-16">
        <v-col sm="12" class="terminal-img-container">
          <img
              :src="terminalData.terminalImage"
              :alt="terminalData.terminalName"
              v-if="terminalData.terminalImage"
          />
          <avatar v-else  :color="companyData.companyColor"
                  :avatar="terminalData.terminalName.charAt(0)" avatar-type="rect" />
        </v-col>
        <v-row class="tw-mt-6 lg:tw-px-12 tw-justify-between tw-flex-col md:tw-flex-row" >
          <v-col  sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-start lg:tw-items-center">
              <v-col sm="6" class="label-key tw-m-0">Name</v-col>
              <v-col sm="6" class="label-value tw-m-0">{{
                  terminalData.terminalName
                }}</v-col>
            </div>
          </v-col>
          <v-col  sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0">Town/City</v-col>
              <v-col sm="6" class="label-value tw-m-0">{{
                  terminalData.address.city
                }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0">Terminal ID</v-col>
              <v-col sm="6" class="label-value tw-m-0">{{
                  terminalData.terminalId
                }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0">State</v-col>
              <v-col sm="6" class="label-value tw-m-0">{{
                  terminalData.address.state
                }}</v-col>
            </div>
          </v-col>

          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0">Street No.</v-col>
              <v-col sm="6" class="label-value tw-m-0">{{
                  terminalData.address.houseNumber
                }}</v-col>
            </div>
          </v-col>

          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0">Country</v-col>
              <v-col sm="6" class="label-value tw-m-0">{{
                  terminalData.address.country
                }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0">Street Name</v-col>
              <v-col sm="6" class="label-value tw-m-0">{{
                  terminalData.address.streetName
                }}</v-col>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </v-col>
</template>

<script>
import {getTerminalById} from "../../../services/api/APIService";
import Loader from "../../reuseables/Loader";
import Icons from "../../reuseables/Icons";
import Avatar from "@/components/reuseables/Avatar";

export default {
  name: "TerminalManagerSingleTerminal",
  components: {Avatar, Icons, Loader},
  data() {
    return {
      terminalId: "",
      companyData: {},
      terminalData: {},
      loading: false,
    };
  },
  methods: {
    backToTerminals() {
      this.$store.dispatch(
        "terminalManagerTerminalComponent/setCurrentComponent",
        "allTerminals"
      );
      sessionStorage.setItem(
        "terminalManagerTerminalComponent",
        "allTerminals"
      );
    },

    async getTerminalById() {
      try {
        const response = await getTerminalById(this.terminalId);
        this.terminalData = await response.data;
        this.loading = false;
      } catch (err) {
        console.log(err.response);
        this.loading = false;
          console.log(err.response);
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
      }
    },
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let terminalId = sessionStorage.getItem("terminalId");
    if (terminalId) {
      this.terminalId = terminalId;
    } else {
      return this.backToTerminals();
    }

    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    await this.getTerminalById();
  },
};
</script>

<style lang="scss" scoped>
.label-key {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.label-value {
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
}
.terminal-img-container {
  width: 100%;
  height: 255px;
  img {
    width: auto;
    height: 100%;
    border-radius: 4px;
  }
}
.overview-btn {
  border: none !important;
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
</style>
