<template>
  <Component v-bind:is="displayTerminalComponent()" />
</template>

<script>
import AllTerminals from "./AllTerminal.vue";
import SingleTerminal from "./SingleTerminal.vue";
import { mapGetters } from "vuex";
export default {
  name: "TerminalManagerTerminals",
  computed: {
    ...mapGetters("terminalManagerTerminalComponent", ["currentComponent"]),
  },
  data() {
    return {};
  },
  methods: {
    displayTerminalComponent() {
      let component = this.currentComponent;
      if (component === "allTerminals") {
        return AllTerminals;
      } else if (component === "singleTerminal") {
        return SingleTerminal;
      }
    },
  },
  created() {
    let component = sessionStorage.getItem("terminalManagerTerminalComponent");

    if (!component) {
      sessionStorage.setItem(
        "terminalManagerTerminalComponent",
        "allTerminals"
      );
      this.$store.dispatch(
        "terminalManagerTerminalComponent/setCurrentComponent",
        "allTerminals"
      );
      this.displayTerminalComponent();
    } else {
      this.$store.dispatch(
        "terminalManagerTerminalComponent/setCurrentComponent",
        component
      );
      this.displayTerminalComponent();
    }
  },
};
</script>

<style lang="scss" scoped></style>
