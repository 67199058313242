import { render, staticRenderFns } from "./ViewTerminalDashboardRoute.vue?vue&type=template&id=830f85ce&scoped=true&"
import script from "./ViewTerminalDashboardRoute.vue?vue&type=script&lang=js&"
export * from "./ViewTerminalDashboardRoute.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "830f85ce",
  null
  
)

export default component.exports