<template>
  <v-col sm="12">
    <loader :color="companyData.companyColor" :loading="loading" v-if="loading"/>
    <div v-else
        class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between
         lg:tw-py-5  tw-mt-20  lg:tw-mt-5 table-container-div tw-p-5 lg:tw-pl-8"
        style="background: white;"
    >
      <div class="table-container lg:tw-pr-7">
        <div class="tw-flex tw-flex-col lg:tw-flex-row  lg:tw-justify-between lg:tw-items-center">
          <p class="table-header" :style="{ color: companyData.companyColor }">
            All Terminals
          </p>
          <div class="tw-flex tw-flex-row lg:tw-justify-center tw-justify-end tw-items-center">
            <div
                class="mr-2"
                style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)"
            >
              <v-text-field
                  placeholder="Search"
                  class="p-0"
                  solo
                  flat
                  hide-details
                  v-model="search"
              >
                <template #prepend>
                  <v-icon :color="companyData.companyColor" class="ml-5">
                    mdi-magnify
                  </v-icon>
                </template>
                <template #append>
                  <v-icon :color="companyData.companyColor">
                    mdi-filter-variant
                  </v-icon>
                </template>
              </v-text-field>
            </div>
          </div>
        </div>

        <v-data-table
            :headers="headers"
            v-model="selectedRow"
            :mobile-breakpoint="0"
            :hide-default-footer="terminals.length <= 10"
            :search="search"
            item-key="id"
            :single-select="singleSelect"
            height="available"
            :items="terminals"
            :no-data-text="not_found"
            class="mt-4 mx-1 table"
            :checkbox-color="companyData.companyColor"
            @click:row="viewSingle"
            :page.sync="page"
            @page-count="pageCount = $event"
        >
          <template #header.sno>
            <span class="status">S/N</span>
          </template>
          <template v-slot:[`item.sno`]="{ item }">
            <span class="td-style">{{ item.sno }}</span>
          </template>
          <template #header.terminalImage>
            <span class="status">Image</span>
          </template>
          <template v-slot:[`item.terminalImage`]="{ item }">
            <img
                v-if="item.terminalImage"
                :src="item.terminalImage"
                alt="terminal"
            />

            <div
                class="tw-flex tw-justify-center tw-items-center no-image"
                v-else
                :style="{ backgroundColor: companyData.companyColor }"
            >
              <avatar :avatar="item.terminalName[0]" :color="companyData.companyColor" />

            </div>
          </template>
          <template #header.terminalId>
            <span class="status">Terminal ID</span>
          </template>
          <template v-slot:[`item.terminalId`]="{ item }">
            <span class="td-style">{{ item.terminalId }}</span>
          </template>
          <template #header.terminalName>
            <span class="status">Terminal Name</span>
          </template>
          <template v-slot:[`item.terminalName`]="{ item }">
            <span class="td-style">{{ item.terminalName }}</span>
          </template>
          <template #header.houseNumber>
            <span class="status">House Number</span>
          </template>
          <template v-slot:[`item.houseNumber`]="{ item }">
            <span class="td-style">{{ item.address.houseNumber }}</span>
          </template>
          <template #header.streetName>
            <span class="status">Street Name</span>
          </template>
          <template v-slot:[`item.streetName`]="{ item }">
            <span class="td-style">{{ item.address.streetName }}</span>
          </template>
          <template #header.townCity>
            <span class="status">Town/City</span>
          </template>
          <template v-slot:[`item.townCity`]="{ item }">
            <span class="td-style">{{ item.address.city }}</span>
          </template>
          <template #header.state>
            <span class="status">State</span>
          </template>
          <template v-slot:[`item.state`]="{ item }">
            <span class="td-style">{{ item.address.state }}</span>
          </template>
          <template #header.country>
            <span class="status">Country</span>
          </template>
          <template v-slot:[`item.country`]="{ item }">
            <span class="td-style">{{ item.address.country }}</span>
          </template>
          <template v-slot:[`item.actions`] ="{ item }">
            <v-menu bottom offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <td class="d-flex justify-center">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon :color="companyData.companyColor">mdi-dots-horizontal</v-icon>
                  </v-btn>
                </td>
              </template>
              <v-list>
                <v-list-item @click="viewSingle(item)">
                  <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-cursor-pointer tw-items-center menu-text">
                    View
                    <v-icon :color="companyData.companyColor" >mdi-arrow-right</v-icon>

                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <div class="tw-pt-10" v-if="terminals.length >= 10">
          <v-pagination :color="companyData.companyColor"
              v-model="page"
              :length="pageCount"
          ></v-pagination>
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
import {getTransportCompanyTerminals} from "@/services/api/APIService";
import Avatar from "../../reuseables/Avatar";
import Loader from "../../reuseables/Loader";
import dayjs from "dayjs";

export default {
  name: "TerminalManagerAllTerminals",
  components: {Loader, Avatar},
  data() {
    return {
      page: 1,
      pageCount: 0,
      companyData: {},
      terminals: [],
      headers: [
        {
          text: '',
          value: 'sno'
        },
        { text: "", value: "terminalImage" },
        { text: "", value: "terminalName" },
        { text: "", value: "terminalId" },
        { text: "", value: "houseNumber" },
        { text: "", value: "streetName" },
        { text: "", value: "townCity" },
        { text: "", value: "state" },
        { text: "", value: "country" },
        { text: "", value: "actions", align: "end" },
      ],
      not_found: "Terminals will display here",
      selectedRow: [],
      singleSelect: false,
      search: "",
      loading: false,


    };
  },
  methods: {
    viewSingle(terminal) {
      this.$store.dispatch(
          "terminalManagerTerminalComponent/setCurrentComponent",
          "singleTerminal"
      );
      sessionStorage.setItem("terminalManagerTerminalComponent", "singleTerminal");
      sessionStorage.setItem("terminalId", terminal.id);
    },
    async getAllTerminals() {
      this.loading = true;
      await getTransportCompanyTerminals(this.companyData.id)
          .then((res) => {
            this.terminals = res.data;
            this.terminals.sort(function (a, b){
              return  dayjs(b.created).toDate() - dayjs(a.created).toDate()
            })
            this.terminals.forEach((ter,index) => ter.sno = index + 1)
            this.loading = false;

          })
          .catch((err) => {
            this.loading = false;
            console.log(err.response);
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            });
          });
    },
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    await this.getAllTerminals();
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
}

.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    object-fit: cover;
  }
}

.no-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;

  p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    margin-bottom: 0 !important;
    color: rgba(255, 255, 255, 1);
  }
}

img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
}


::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 0 0 5px;
}

.menu-text{
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;

}
</style>
